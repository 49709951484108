





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ListOfAssets } from '@/modules/listOfAssets/types';
import FinanceAccountTitlePlugin from '../cards/FinanceAccountTitlePlugin.vue';
import BankAccountsPlugin from '@/modules/listOfAssets/components/BankAccountsPlugin.vue';
import CashAccountsPlugin from '@/modules/listOfAssets/components/CashAccountsPlugin.vue';
import CreditAccountsPlugin from '@/modules/listOfAssets/components/CreditAccountsPlugin.vue';
import InternalAccountsPlugin from '@/modules/listOfAssets/components/InternalAccountsPlugin.vue';

@Component({
  components: { CreditAccountsPlugin, CashAccountsPlugin, BankAccountsPlugin, FinanceAccountTitlePlugin, InternalAccountsPlugin }
})
export default class FinanceAccountTab extends Vue {
  @Prop({ type: Object }) listOfAssets?: ListOfAssets;
}
